import React from 'react'
import Components from '../components/components.js'
import Footer from '../components/shared/Footer'
import { Helmet } from "react-helmet"
import config from '../../gatsby-config';
import StoryblokClient from 'storyblok-js-client';

let sbConfigs = config.plugins.filter((item) => {
  return item.resolve === 'gatsby-source-storyblok'
});
let sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {}


class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null
    }

    return StoryblokEntry.prepareStory(props)
  }

  static prepareStory(props) {
    const story = Object.assign({}, props.pageContext.story)
    const globalNavi = Object.assign({}, props.pageContext.globalNavi)
    const globalSettings = Object.assign({}, props.pageContext.globalSettings)
    const footer = Object.assign({}, props.pageContext.footer)
    story.content = JSON.parse(story.content)
    globalNavi.content = JSON.parse(globalNavi.content)
    globalSettings.content = JSON.parse(globalSettings.content)
    footer.content = JSON.parse(footer.content)
    return { story, globalNavi, footer, globalSettings }
  }

  constructor(props) {
    super(props)

    this.storyblokClient = new StoryblokClient({
      accessToken: sbConfig.options.accessToken
    });

    this.state = StoryblokEntry.prepareStory(props)
  }

  render() {
    let content = { ...this.state.story.content, storyblokClient: this.storyblokClient }
    let footer = this.state.footer.content
    let settings = this.state.globalSettings.content

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="title"
            content={ settings['search_title'] }
          />
          <meta
            name="og:image"
            content={ settings['og_image'] }
          />
          <meta
            name="og:description"
            content={ settings['og_description'] }
          />
          <title>{ settings.title }</title>
          { settings.favicon
            && <link rel="icon" href={settings.favicon.filename} type="image/png" sizes="16x16" />
          }
        </Helmet>
        {React.createElement(Components(content.component), {key: content._uid, blok: content, settings, footer})}
      </div>
    )
  }
}

export default StoryblokEntry
